import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";

import { useForm, SubmitHandler } from "react-hook-form";
import SweetAlert from "sweetalert2-react";
import md5 from "md5";
import { useMutation } from "@apollo/client";

import SafeWebKeyIcon from "../../images/safe_webkey_icon.png";
import UserIcon from "../../images/user@2x.png";
import ParkGenieImg from "../../images/login-image.png";
import ParkGenieLogo from "../../images/logo_partner@2x.png";
import QrCode from "../../images/qr-code-user@2x.png";

import Spinner from "../spinner";

import { Alert } from "../../Interfaces/Alert";
import { User } from "../../Interfaces/User";

import { createUserQuery } from "../../Query/User";

import * as SignupStyle from "./Signup.module.scss";
import { popoverAlert } from "../../utils/Alert";
import Logger from "../../utils/Logger";

interface Inputs {
  name: string;
  contactNumber: string;
  password: string;
  confirmPassword: string;
  aggreeTnc: boolean;
  email: string;
}

const Index = () => {
  const {
    register,
    handleSubmit,
    formState: { dirtyFields, errors },
  } = useForm();

  const [createUser, { loading, error, data }] =
    useMutation<{ createUser: { user: User } }>(createUserQuery);

  const onHandleSubmit: SubmitHandler<Inputs> = ({
    aggreeTnc,
    confirmPassword,
    contactNumber,
    name,
    password,
    email,
  }) => {
    if (!aggreeTnc)
      popoverAlert({
        icon: "warning",
        title: "Warning",
        msg: "Please Accept Terms and Conditions to continue",
      });

    if (confirmPassword !== password)
      popoverAlert({
        icon: "warning",
        title: "Warning",
        msg: "Both Password did not match",
      });

    createUser({
      variables: {
        name,
        email: email.length > 1 ? email : undefined,
        contactNumber,
        password: md5(password),
      },
    })
      .then((response) => {
        const shortUserId = response.data?.createUser?.user?.id?.split("/")[2];
        navigate(`/otp/${shortUserId}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const err = error;
    if (err) {
      Logger.showError(err);
    }
  }, [error]);
  return (
    <div className={SignupStyle.container}>
      <div
        {...{ style: { backgroundImage: `url(${ParkGenieImg})` } }}
        className={SignupStyle.pargenieAd}
      >
        <div>
          <img src={ParkGenieLogo} alt="logo" width={150} />
        </div>
        <div className={SignupStyle.pargenieAd__qrcode}>
          <span>
            When you want to book parking. You will have to use the ParkGenie
            User App.The ParkGenie User App can be downloaded by using the below
            QR Code
          </span>
          <img src={QrCode} alt="qrcode" width={150} />
        </div>
      </div>
      <form
        className={SignupStyle.form}
        onSubmit={handleSubmit(onHandleSubmit)}
      >
        <div className="boldText color-primary heading1">Sign Up</div>
        <div className={SignupStyle.form__group}>
          <input
            placeholder="Enter Your Name"
            className="input"
            type="text"
            {...register("name", { required: true })}
            required
          />
        </div>
        <div className={SignupStyle.form__group}>
          <input
            placeholder="+91 Enter Your Mobile Number"
            className="input"
            type="tel"
            {...register("contactNumber", { required: true })}
            required
          />
        </div>
        <div className={SignupStyle.form__group}>
          <input
            placeholder="Enter Your Email Address (Optional)"
            className="input"
            type="email"
            {...register("email")}
          />
        </div>
        <div className={SignupStyle.form__group}>
          <input
            placeholder="Enter Password"
            className="input"
            type="password"
            {...register("password", { required: true })}
            required
          />
        </div>
        <div className={SignupStyle.form__group}>
          <input
            placeholder="Re-enter Password"
            className="input"
            type="password"
            {...register("confirmPassword", { required: true })}
            required
          />
        </div>
        <div className={SignupStyle.form__group}>
          <input type="checkbox" {...register("aggreeTnc")} />
          <div className="color-primary">I Accept The T&amp;C of ParkGenie</div>
        </div>
        <div className={`${SignupStyle.form__group} justify-center`}>
          <div className={SignupStyle.form__group_signup}>
            <Spinner showLoader={loading && !error && !data}>
              <button
                className="btn btn__primary"
                type="submit"
                value="Sign up"
              >
                Sign up
              </button>
            </Spinner>
          </div>
        </div>

        <div className={`${SignupStyle.orText} color-primary textMd`}>Or</div>
        <div className={`${SignupStyle.form__group} justify-center`}>
          <div className={SignupStyle.form__group_swk}>
            <button
              className="btn grediant1"
              value="Sign up"
              onClick={() => navigate("/login")}
            >
              <img src={UserIcon} alt="login" width={24} height={24} />
              <span>Already Registered? / Login</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Index;
