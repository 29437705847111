import React from "react";

import Layout from "../Components/Layout";
import Signup from "../Components/Signup";

const SignupPage = () => {
  return (
    <Layout nonLogingPage showHeader>
      <Signup />
    </Layout>
  );
};

export default SignupPage;
